import { Form, FormInstanceFunctions, Input, InputValue } from 'tdesign';
import FormItem from 'common/components/Form/FormItem';
import StrategyDescription, { IPolicy, pwdValidator } from 'common/components/StrategyDescription';
import { LABEL_TEXT, PWD_CONFIRM_TYPE } from './Constants';
import PasswordConfirmScss from './PasswordConfirm.module.scss';

interface IProps {
  onEnter: () => void;
  type: PWD_CONFIRM_TYPE;
  formRef: React.RefObject<FormInstanceFunctions>;
  policy: IPolicy;
  pwdErrMsg?: string;
  onChange?: (value: InputValue, name: string) => void;
}

const PwdSetup: React.FC<IProps> = (props: IProps) => {
  const { onEnter, type, formRef, policy, pwdErrMsg, onChange } = props;

  const [isValidate, setIsValidate] = React.useState(false);

  const text = m.get(LABEL_TEXT, type);

  return (
    <Form ref={formRef} labelAlign="top">
      <FormItem
        label={
          <div className={PasswordConfirmScss.labelWrapper}>
            <span className={PasswordConfirmScss.pwdLabel}>{text}</span>
            <StrategyDescription policy={policy} />
          </div>
        }
        name="password"
        status={pwdErrMsg ? 'error' : undefined}
        tips={pwdErrMsg}
        rules={[
          { required: true, message: `请输入${text}` },
          {
            password: true,
            validator: pwdValidator(policy),
          },
          {
            validator: (val) => {
              const confirmPwd = formRef?.current?.getFieldValue?.('confirmPassword') || '';
              if (confirmPwd) {
                setIsValidate(true);
                return val === confirmPwd;
              }
              return true;
            },
            message: '两次输入的密码需要相同',
          },
        ]}
      >
        <Input
          placeholder={text}
          type="password"
          onEnter={onEnter}
          onChange={(val) => {
            if (formRef?.current?.getFieldValue?.('confirmPassword') && isValidate) {
              formRef?.current?.validate?.({ fields: ['confirmPassword'] });
            }
            if (_.isFunction(onChange)) {
              onChange(val, 'password');
            }
          }}
          onBlur={() => {
            if (formRef?.current?.getFieldValue?.('confirmPassword')) {
              formRef?.current?.validate?.({ fields: ['confirmPassword', 'password'] });
            }
          }}
        />
      </FormItem>
      <FormItem
        label={`再次输入${text}`}
        name="confirmPassword"
        rules={[
          { required: true, message: `再次输入${text}` },
          {
            validator: (val) => {
              const password = formRef?.current?.getFieldValue?.('password') || '';
              if (password) {
                setIsValidate(true);
                return val === password;
              }
              return true;
            },
            message: '两次输入的密码需要相同',
          },
        ]}
      >
        <Input
          placeholder={`再次输入${text}`}
          type="password"
          onEnter={onEnter}
          onChange={() => {
            // NOTICE 失去焦点校验之后，做实时校验
            if (formRef?.current?.getFieldValue?.('password') && isValidate) {
              formRef?.current?.validate?.({ fields: ['password'] });
            }
          }}
          onBlur={() => {
            if (formRef?.current?.getFieldValue?.('password'))
              formRef?.current?.validate?.({ fields: ['password', 'confirmPassword'] });
          }}
        />
      </FormItem>
    </Form>
  );
};

export default React.memo(PwdSetup);

import 'common/utils/initEnv';
import 'common/store/initMobxConfig';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import AegisV2 from '@tencent/aegis-web-sdk-v2';
import { defaultConfig, handleBeforeSend } from 'common/utils/aegis';
import JSRuntime from 'common/utils/JSRuntime';
import globalStore, { Provider } from 'idpBase/store';
import RootContainer from './modules/root';
import 'common/styles/main.scss';
import 'tdesign-mobile-react/es/style/index.css'; // 少量公共样式
import './static/css/main.scss';
import 'animate.css';

if (JSRuntime.isProductSite) {
  /** 监控报警需要上报的域名 */
  const AEGIS_ASSETS_DOMAINS = [
    window.ONEID_PUBLIC_IDP_SITE,
    window.ONEID_PUBLIC_CDN_URL,
    'https://turing.captcha',
  ];

  window.aegis = new AegisV2({
    ...defaultConfig,
    id: 'SDK-69d659a949c29ce2ae13',
    onBeforeSend: handleBeforeSend(AEGIS_ASSETS_DOMAINS),
  });
}

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <Provider store={globalStore}>
    <React.Suspense fallback={<div>loading...</div>}>
      <HashRouter>
        <RootContainer />
      </HashRouter>
    </React.Suspense>
  </Provider>,
);

/**
 * 用于 TDesign 的 Form组件，Form.FormItem将不会直接被使用；
 */
import { Form, FormItemProps, FormRule, Tooltip } from 'tdesign';
import validator from 'validator';
import Icon from 'common/components/Icon';
import Utils from 'common/utils';
import FormScss from './Form.module.scss';

// 用于 TDesign Form 的input长度validator，TDesign默认的max和min认为一个中文等于两个字符
const inputLenValidator = (val: string, max: number, min?: number) => {
  const value = val ?? '';
  if (min) {
    // 有最小长度，但不做非空限制，不输入也可 即value === ''
    return value === '' || (value.length >= min && (max > 0 ? value.length <= max : true));
  }
  return value.length <= max;
};

//  用于 TDesign Form整数范围的校验,优先级高于单独的min、max
const integerRangeValidator = (
  val: string,
  range?: {
    min?: number;
    max?: number;
  },
) => {
  if (/^([1-9]\d*|0)$/.test(val)) {
    const newVal = Number(val);
    const max = range?.max;
    const min = range?.min;
    if (_.isNumber(max) && !_.isNumber(min)) return newVal <= max;
    if (_.isNumber(min) && !_.isNumber(max)) return newVal >= min;
    return _.isNumber(min) && _.isNumber(max) && newVal >= min && newVal <= max;
  }
  return false;
};

const PWD_RULES = {
  CHECK_LENGTH: {
    text: '密码字符长度需要在8～16位',
    validator: (value: string) => value.length >= 8 && value.length <= 16,
  },
  CHECK_STRENGTH: {
    text: '密码强度太弱，请使用包含大写字母、小写字母、数字的组合',
    sub: [
      {
        text: '小写字母 a ~ z',
        validator: (value: string) => /[a-z]/.test(value),
      },
      {
        text: '大写字母 A ～ Z',
        validator: (value: string) => /[A-Z]/.test(value),
      },
      {
        text: '数字 0 ～ 9',
        validator: (value: string) => /[0-9]/.test(value),
      },
    ],
  },
  CHECK_LEGALITY: {
    text: '密码中不可包含除英文、数字、英文符号之外的字符',
    validator: (value: string) => /^[\x21-\x7e ]+$/.test(value),
  },
};

interface Rule extends FormRule {
  password?: boolean;
  integerRange?: boolean;
  [key: string]: any;
}

interface Props extends FormItemProps {
  rules?: Rule[];
  labelTip?: React.ReactNode;
}

const FormItem: React.FC<Props> = (props: Props) => {
  const { rules, label, labelTip, children, ...restProps } = props;
  const [hasValidated, setValidated] = React.useState(false);
  const ref = React.useRef<{ validate: () => void }>(null);

  // 自定义校验：默认blur时校验
  // 一旦表单失焦一次后后续每次通过触发ref?.current?.validate?.() 来达到实时校验效果
  const validate = React.useCallback(
    (rule: Rule): Rule => {
      const { validator, ...rest } = rule;
      return {
        validator: _.isFunction(validator)
          ? (val: string) => {
              if (!hasValidated) setValidated(true);
              return validator(val);
            }
          : undefined,
        trigger: 'blur',
        ...rest,
      };
    },
    [hasValidated],
  );

  let newRules = rules;
  if (_.isArray(rules)) {
    newRules = rules.map((rule) => {
      if (rule.validator) {
        return validate(rule);
      }
      if (rule.required || rule.whitespace) {
        return validate({
          ..._.omit(rule, ['required', 'whitespace']),
          validator: (val: any) => {
            if (_.isNumber(val)) {
              return String(val).trim().length > 0; // 数字 0 的情况
            }
            if (_.isString(val)) {
              return val.trim().length > 0;
            }
            if (_.isArray(val)) {
              return val.length > 0;
            }
            if (_.isBoolean(val)) {
              return _.isBoolean(val);
            }
            return !!val;
          },
        });
      }
      if (rule.integerRange) {
        return validate({
          ..._.omit(rule, 'integerRange'),
          validator: (val: string) =>
            integerRangeValidator(val, {
              min: rule.min as number,
              max: rule.max as number,
            }),
        });
      }
      if (_.isNumber(rule.max)) {
        return validate({
          ..._.omit(rule, 'max'),
          validator: (val: string) => inputLenValidator(val, rule.max as number),
        });
      }
      if (rule.telnumber) {
        return validate({
          ..._.omit(rule, 'telnumber'),
          // 空字符串不在特殊格式校验中
          // src/admin/modules/sync/settings/modules/SyncDelProtect/NoticeReceiver.ts 该文件中有参考此处的校验格式
          validator: (val: string) => !val || Utils.isValidPhoneNumber(val),
        });
      }
      if (rule.email) {
        return validate({
          ..._.omit(rule, ['email', 'isNewValidate']),
          validator: (val: string) => {
            // 空字符串不在特殊格式校验中
            if (!val) return true;

            const newValidate = Utils.isValidEmail(val);
            // 以下正则保留参考
            // const dotAtom = `[${regStr}]+(.[${regStr}]+)*`;
            // const reg = new RegExp(`^${dotAtom}@${dotAtom}$`);
            // const newValidate = reg.test(val);
            return rule?.isNewValidate ? newValidate : validator.isEmail(val) || newValidate;
          },
        });
      }
      if (rule.url) {
        return validate({
          ..._.omit(rule, 'url'),
          // 空字符串不在特殊格式校验中
          validator: (val: string) =>
            !val ||
            validator.isURL(val, {
              protocols: ['https', 'http'],
              require_protocol: true,
              require_valid_protocol: true,
              require_tld: false,
              ...(_.isBoolean(rule.url) ? {} : rule.url),
            }),
        });
      }
      if (rule.password) {
        return validate({
          ..._.omit(rule, 'password'),
          validator: (val: string) => {
            // 空字符串不在特殊格式校验中
            if (!val) return true;

            if (!PWD_RULES.CHECK_LEGALITY.validator(val)) {
              return {
                result: false,
                message: PWD_RULES.CHECK_LEGALITY.text,
                type: 'error',
              };
            }

            if (!PWD_RULES.CHECK_LENGTH.validator(val)) {
              return {
                result: false,
                message: PWD_RULES.CHECK_LENGTH.text,
                type: 'error',
              };
            }
            if (!PWD_RULES.CHECK_STRENGTH.sub.every((r) => r.validator(val))) {
              return {
                result: false,
                message: PWD_RULES.CHECK_STRENGTH.text,
                type: 'error',
              };
            }
            return true;
          },
        });
      }
      if (_.isNumber(rule.min)) {
        return validate({
          ..._.omit(rule, 'min'),
          validator: (val: string) => inputLenValidator(val, -1, rule.min as number),
        });
      }
      return rule;
    });
  }

  let newLabel = label;
  if (labelTip && label) {
    // label结尾是中文括号，icon不设置左边距
    const isEndWithParentheses = _.isString(label) && label.endsWith('）');
    newLabel = (
      <>
        {label}
        <Tooltip
          theme="light"
          content={labelTip}
          placement="bottom-left"
          popperOptions={{
            modifiers: [
              {
                name: 'arrow',
                options: {
                  element: '.t-popup__arrow',
                  padding: 14,
                },
              },
            ],
          }}
        >
          <span className={FormScss.labelTipWrapper}>
            <Icon
              type="TxHelpCircle"
              size={16}
              className={Utils.uniteClass(
                FormScss.labelTip,
                isEndWithParentheses ? '' : FormScss.iconWithMargin,
              )}
              pointer
            />
          </span>
        </Tooltip>
      </>
    );
  }

  return (
    <Form.FormItem ref={ref} {...restProps} rules={newRules} label={newLabel}>
      {Array.isArray(rules)
        ? React.cloneElement(children as React.ReactElement, {
            onChange: (...args: any[]) => {
              const prevOnChange = _.get(children, 'props.onChange');
              if (_.isFunction(prevOnChange)) prevOnChange(...args);
              if (hasValidated) {
                setTimeout(() => {
                  ref?.current?.validate?.();
                }, 0);
              }
            },
          })
        : children}
    </Form.FormItem>
  );
};

export { inputLenValidator };

export default FormItem;
